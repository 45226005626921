import { io, Socket } from "socket.io-client";
import store from "../redux/store";
import { updateData } from "../redux/slices/dataSlice";



class WebSocketService {
  private socket: Socket | null = null;

  // Initialize the Socket.IO connection with JWT authentication
  connect(jwtToken: string, options?: any): void {
    if (this.socket) {
      console.warn("WebSocket is already connected.");
      return;
    }

    const url = process.env.REACT_APP_WEBSOCKET_URL;
    if (!url) {
      console.error("WebSocket URL is not defined in environment variables.");
      return;
    }
    this.socket = io(url, {
      ...options,
      extraHeaders: {
        authorization: `bearer ${jwtToken}`,
      }
    });

    // Register default event handlers
    this.socket.on("connect", () => {
      console.log("Socket.IO connected!");
    });

    this.socket.on("disconnect", () => {
      console.log("Socket.IO disconnected!");
    });

    this.socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });
  }

  // Disconnect the Socket.IO connection
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    } else {
      console.warn("WebSocket is not connected.");
    }
  }

  // Send a message through the WebSocket
  send(event: string, data: any): void {
    if (this.socket) {
      this.socket.emit(event, data);
    } else {
      console.error("WebSocket is not connected.");
    }
  }

  // Add an event listener for a specific event
  on(event: string, callback: (...args: any[]) => void): void {
    if (this.socket) {
      this.socket.on(event, callback);
    } else {
      console.error("WebSocket is not connected.");
    }
  }

  // Remove an event listener for a specific event
  off(event: string, callback?: (...args: any[]) => void): void {
    if (this.socket) {
      if (callback) {
        this.socket.off(event, callback);
      } else {
        this.socket.off(event);
      }
    } else {
      console.error("WebSocket is not connected.");
    }
  }

  joinRoom(roomId: string): void {
    if (this.socket) {
      this.socket.emit('joinRoom', roomId);
      this.socket.on(`m_${roomId}_update`, (data) => {
        // Dispatch the updateData action
        store.dispatch(updateData({ roomId, data }));
      });
    } else {
      console.error("Socket is not connected.");
    }
  }
}

const websocketService = new WebSocketService();
export default websocketService;
