import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

export interface Option {
  value: number | string;
  label?: string;
}

interface SelectFieldProps {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  placeholder?: string;
  label?: string
}

const SelectField: React.FC<SelectFieldProps> = ({ value, onChange, options, placeholder, label }) => {
  return (
    <InputGroup className="mb-3">
      {label && <InputGroup.Text>{label}</InputGroup.Text>}
      <Form.Select value={value} onChange={onChange}>
        <option value="" disabled>{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  );
};

export default SelectField;