import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevices, selectDevices, selectDevicesLoading, selectDevicesError } from '../../redux/slices/devices';
import { Device } from '../../types';

const DeviceList: React.FC = () => {
  const dispatch = useDispatch<any>();
  const devices = useSelector(selectDevices);
  const loading = useSelector(selectDevicesLoading);
  const error = useSelector(selectDevicesError);

  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  return (
    <div>
      <h2>Devices</h2>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      <ul>
        {devices.map((device: Device) => (
          <li key={device.id}>{device.serialNumber} - {device.someOtherField}</li>
        ))}
      </ul>
    </div>
  );
};

export default DeviceList;
