import { PadColorKeys } from "../theme/colors";

export interface Partner {
  id: string;
  name: string;
}

export enum Integrations {
  NONE = 'None',
  COVETRUS = 'Covetrus',
  SHEPARD = 'Shepard',
  VETSPIRE = 'Vetspire',
  DIGITAIL = 'Digitail',
}
export interface Configuration {
  pimsEnabled: boolean;
  integrationType: Integrations
  publicKey?: string;
  installationKey?: string;
  privateKey?: string
}

export interface Customer {
  id: string;
  name: string;
  partnerId?: string;
  configuration?: Configuration
}

export interface Device {
  id: string;
  serialNumber: string;
  productLine: string;
  modelNumber: string;
  hardwareRevision: string;
  weekOfManufacture: number;
  yearOfManufacture: number;
  whiteLabelPartner: string;
  sequentialNumber: number;
  someOtherField: string;
  active: boolean;
  session?: Session
  customerId?: string;
  inClinicLocation?: string;
  colorCode?: PadColorKeys;
  inClinicLocationNumber?: number
  name?: string
}

export interface Patient {
  id: string;
  customerId: string;
  species: 'dog' | 'cat' | '--';
  breed: string;
  name: string;
  sex: Sex;
  doctor?: string
  phone?: string
}

export interface Badge {
  number: string;
  color: string;
  location: string;
}

export interface Vitals {
  pulse: number;
  resp: number;
  temp: number;
  weight: number;
}

export enum Sex {
  MALE_INTACT = 'male - intact',
  MALE_NEUTERED = 'male - neutered',
  FEMALE_INTACT = 'female - intact',
  FEMALE_SPAYED = 'female - spayed',
}

export interface Session {
  id: string;
  startTime: string;
  endTime?: string | null;
  patientId?: string;
  deviceId?: string;
  patient?: Patient
  devive?: Device
  badge?: Badge
}

export interface VitalsDTO {
  ts: number | string;
  hr: number;
avgHR: number;
  actHR: number;
  rr: number;
  avgRR: number;
  actRR: number;
  gt: {
    rr: number,
    hr: number
  },
  stale?: boolean;
  validTemp: boolean;
  temp: number;
  session_id: string;
  machine_id: string;
  weight: number;
  meta: {
    continuousData: boolean;
    gt: boolean
    validGt?: boolean;
    clippedScope?: boolean;
    clippedVibration: boolean;
    significateWeight: boolean;
    weightSettled: boolean;
    validVibration: boolean;
    heartbeat: boolean;
    vhrValid: boolean
    vrrValid: boolean
    shrValid?: boolean
    frrValid?: boolean
  },
}
