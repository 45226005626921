import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartners, createPartner, updatePartner, deletePartner, setEditPartner } from '../../redux/slices/partners';
import { RootState } from '../../redux/rootReducer'
import { checkAuthState } from '../../redux/slices/authSlice';
import PartnerItem from '../molecules/PartnersItem'

const PartnerList: React.FC = () => {
  const dispatch = useDispatch<any>()
  const partners = useSelector((state: RootState) => state.partners.list);
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);


  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchPartners());
    }
  }, [dispatch, isAuthenticated]);

  const handleEdit = (id: string, name: string) => {
    dispatch(setEditPartner({ id, name }));
  };

  return (
    <ul>
      {partners.map(partner => (
        <PartnerItem
          key={partner.id}
          partner={partner}
          onEdit={() => handleEdit(partner.id, partner.name)}
          onDelete={() => dispatch(deletePartner(partner.id))}
        />
      ))}
    </ul>
  );
};

export default PartnerList;
