import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { loginUser } from '../../redux/slices/authSlice';
import FormField from '../molecules/FormField';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Button from '../atoms/Button';
import { Container } from 'react-bootstrap';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  if (isAuthenticated) {
    return <div>Welcome back, {user?.displayName || user?.email}</div>;
  }

  return (
    <Container>
      <br />
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>Login</Card.Title>
          <form onSubmit={handleLogin}>
            <FormField
              id="email"
              label="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Card.Text>
              <FormField
                id="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer', marginLeft: 8 }}>
                {showPassword ? <FaLockOpen /> : <FaLock />}
              </span>
            </Card.Text>
            <Button type="submit" className="btn btn-primary">Login</Button>
          </form>
        </Card.Body>
      </Card></Container>
  );
};

export default LoginPage;
