type LogLevel = 'info' | 'warn' | 'error' | 'debug';

interface LoggerOptions {
    [key: string]: boolean;
}

class Logger {
    private options: LoggerOptions;

    constructor() {
        this.options = {};
    }

    configure(options: LoggerOptions) {
        this.options = options;
    }

    setLogging(area: string, enabled: boolean) {
        this.options[area] = enabled;
    }

    log(area: string, level: LogLevel, ...args: any[]) {
        if (this.options[area]) {
            console[level](`[${area}]`, ...args);
        }
    }

    info(area: string, ...args: any[]) {
        this.log(area, 'info', ...args);
    }

    warn(area: string, ...args: any[]) {
        this.log(area, 'warn', ...args);
    }

    error(area: string, ...args: any[]) {
        this.log(area, 'error', ...args);
    }

    debug(area: string, ...args: any[]) {
        this.log(area, 'debug', ...args);
    }

    getLogging(area: string): boolean {
        return this.options[area];
    }
}

const logger = new Logger();

export default logger;
