import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Session } from '../../types';
import { fetchWithJwt } from '../../utils/fetchWithJwt';

interface SessionState {
    sessions: Session[];
    loading: boolean;
    error: string | null;
}

const initialState: SessionState = {
    sessions: [],
    loading: false,
    error: null,
};

const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        fetchSessionsStart(state) {
            state.loading = true;
        },
        fetchSessionsSuccess(state, action: PayloadAction<Session[]>) {
            state.loading = false;
            state.sessions = action.payload;
        },
        fetchSessionsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addSessionSuccess(state, action: PayloadAction<Session>) {
            state.sessions.push(action.payload);
        },
        endSessionSuccess(state, action: PayloadAction<Session>) {
            const index = state.sessions.findIndex(session => session.id === action.payload.id);
            if (index !== -1) {
                state.sessions[index] = action.payload;
            }
        },
    },
});

export const {
    fetchSessionsStart,
    fetchSessionsSuccess,
    fetchSessionsFailure,
    addSessionSuccess,
    endSessionSuccess,
} = sessionSlice.actions;

export const fetchSessions = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchSessionsStart());
        const response = await fetchWithJwt('/sessions');
        const data: Session[] = await response.json();
        dispatch(fetchSessionsSuccess(data));
    } catch (error:any) {
        dispatch(fetchSessionsFailure(error.toString()));
    }
};

export const addSession = (session: Omit<Session, 'id' | 'startTime' | 'endTime'>): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt('/sessions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(session),
        });
        const data: Session = await response.json();
        dispatch(addSessionSuccess(data));
    } catch (error:any) {
        dispatch(fetchSessionsFailure(error.toString()));
    }
};

export const endSession = (id: string): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt(`/sessions/${id}/end`, {
            method: 'POST',
        });
        const data: Session = await response.json();
        dispatch(endSessionSuccess(data));
    } catch (error:any) {
        dispatch(fetchSessionsFailure(error.toString()));
    }
};

export const selectSessions = (state: RootState) => state.sessions.sessions;
export const selectSessionsLoading = (state: RootState) => state.sessions.loading;
export const selectSessionsError = (state: RootState) => state.sessions.error;

export default sessionSlice.reducer;
