import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPartner, updatePartner } from '../../redux/slices/partners';
import { RootState } from '../../redux/rootReducer'
import InputField from '../atoms/InputField';
import Button from '../atoms/Button';

const PartnerForm: React.FC = () => {
  const dispatch = useDispatch<any>()
  const editPartner = useSelector((state: RootState) => state.partners.editPartner);
  const [name, setName] = useState('');

  useEffect(() => {
    if (editPartner) {
      setName(editPartner.name);
    }
  }, [editPartner]);

  const handleCreateOrUpdate = () => {
    if (editPartner) {
      dispatch(updatePartner({ id: editPartner.id, name }));
    } else {
      dispatch(createPartner(name));
    }
    setName('');
  };

  return (
    <div>
      <InputField value={name} onChange={e => setName(e.target.value)} placeholder="Partner Name" />
      <Button onClick={handleCreateOrUpdate}>
        {editPartner ? 'Update' : 'Create'}
      </Button>
    </div>
  );
};

export default PartnerForm;
