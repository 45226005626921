import React, { useState, useEffect } from 'react';

interface DigitalClockProps {
  backgroundColor: string;
  textColor: string;
  isMilitaryTime: boolean;
}

const DigitalClock: React.FC<DigitalClockProps> = ({ backgroundColor, textColor, isMilitaryTime }) => {
  const [time, setTime] = useState<string>(new Date().toLocaleTimeString([], { hour12: !isMilitaryTime }));

  useEffect(() => {
    const timerId = setInterval(() => {
      const options: Intl.DateTimeFormatOptions = isMilitaryTime
        ? { hour12: false }
        : { hour12: true };
      setTime(new Date().toLocaleTimeString([], options));
    }, 1000);

    return () => clearInterval(timerId);
  }, [isMilitaryTime]);

  const clockStyle: React.CSSProperties = {
    fontFamily: "'Courier New', Courier, monospace",
    fontSize: '2rem',
    color: textColor,
    backgroundColor: backgroundColor,
    borderRadius: '10px',
    textAlign: 'center',
    width: 'fit-content',
    margin: 'auto',
  };

  return <div style={clockStyle}>{time}</div>;
};

export default DigitalClock;