import React from 'react';
import './NotFoundPage.css';

const NotFoundPage: React.FC = () => {
  return (
    <div className="not-found-page">
      <h1>404 - Page Not Found</h1>
      <img src="/images/dog-cat-404.jpg" alt="Dogs and Cats" />
      <p>It looks like this page went on a walk with our dogs and cats!</p>
    </div>
  );
};

export default NotFoundPage;
