import React from 'react';
import { Form } from 'react-bootstrap';

interface CheckboxFieldProps {
    id: string;
    label: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ id, label, checked, onChange }) => {
    return (
        <div className="mb-3">
            <Form.Group controlId={id}>
                <Form.Check
                    type="checkbox"
                    label={label}
                    checked={checked}
                    onChange={onChange}
                />
            </Form.Group>
        </div>
    );
};

export default CheckboxField;
