import React from 'react';
import Label from '../atoms/Label';
import Input from '../atoms/InputField';
import Select from '../atoms/SelectField';
import CheckboxField from '../atoms/CheckboxField';

interface FormFieldProps {
    id: string;
    label: string;
    type: 'text' | 'checkbox' | 'select' | 'password';
    value?: string | number | boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
    options?: { value: number | string; label: string }[];
    placeholder?: string;
}

const FormField: React.FC<FormFieldProps> = ({ id, label, type, value, onChange, options, placeholder }) => {
    return (
        <div>
            <Label htmlFor={id}>{label}</Label>
            {type === 'select' ? (
                <Select
                    value={value as string | number}
                    onChange={onChange as (e: React.ChangeEvent<HTMLSelectElement>) => void}
                    options={options || []}
                    placeholder={placeholder}
                />
            ) : type === 'checkbox' ? (
                <CheckboxField
                    id={id}
                    label={label}
                    checked={value as boolean}
                    onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
                />
            ) : (
                <Input
                    value={value as string}
                    onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
                    placeholder={placeholder || ''}
                    type={type} // Ensure the Input component accepts the type prop
                />
            )}
        </div>
    );
};

export default FormField;
