import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Partner } from '../../types';
import { combinePathWithDomain } from '../../utils/combinePathWithDomain';

interface PartnerState {
  list: Partner[];
  editPartner: Partner | null;
}

const initialState: PartnerState = {
  list: [],
  editPartner: null,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartners: (state, action: PayloadAction<Partner[]>) => {
      state.list = action.payload;
    },
    addPartner: (state, action: PayloadAction<Partner>) => {
      state.list.push(action.payload);
    },
    editPartner: (state, action: PayloadAction<Partner>) => {
      const index = state.list.findIndex(p => p.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
    removePartner: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(p => p.id !== action.payload);
    },
    setEditPartner: (state, action: PayloadAction<Partner | null>) => {
      state.editPartner = action.payload;
    },
  },
});

export const { setPartners, addPartner, editPartner, removePartner, setEditPartner } = partnersSlice.actions;

const getAuthToken = (state: RootState): string | null => state.auth.user?.token || null;

const isUserLoaded = (state: RootState): boolean => state.auth.isAuthenticated;

export const fetchPartners = (): AppThunk => async (dispatch, getState) => {
  
  if (!isUserLoaded(getState())) return;

  const token = getAuthToken(getState());
  const response = await fetch(combinePathWithDomain('partners'), {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  const partners: Partner[] = await response.json();
  dispatch(setPartners(partners));
};

export const createPartner = (name: string): AppThunk => async (dispatch, getState) => {
  
  if (!isUserLoaded(getState())) return;

  const token = getAuthToken(getState());
  const response = await fetch(combinePathWithDomain('partners'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ name }),
  });
  const newPartner: Partner = await response.json();
  dispatch(addPartner(newPartner));
};

export const updatePartner = (partner: Partner): AppThunk => async (dispatch, getState) => {
  
  if (!isUserLoaded(getState())) return;
  
  const token = getAuthToken(getState());
  
  const response = await fetch(combinePathWithDomain(`/partners/${partner.id}`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ name: partner.name }),
  });
  const updatedPartner: Partner = await response.json();
  dispatch(editPartner(updatedPartner));
};

export const deletePartner = (id: string): AppThunk => async (dispatch, getState) => {

  if (!isUserLoaded(getState())) return;

  const token = getAuthToken(getState());
  await fetch(combinePathWithDomain(`/partners/${id}`), {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  dispatch(removePartner(id));
};

export default partnersSlice.reducer;
