import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { auth } from '../../firebaseConfig';
import { User as FirebaseUser, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { RootState } from '../rootReducer';

interface User {
  uid: string;
  email: string | null;
  displayName: string | null;
  token: string | null; 
  role: string | null;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    logoutSuccess(state) {
      state.user = null;
    },
    logoutFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;

export const loginUser = (email: string, password: string): AppThunk => async dispatch => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      dispatch(setUser({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        token: idTokenResult.token,
        role: idTokenResult.claims.role as string
      }));
    }
  } catch (error) {
    console.error('Error logging in: ', error);
  }
};

export const logout = (): AppThunk => async dispatch => {
  try {
    await signOut(auth);
    dispatch(clearUser());
  } catch (error) {
    console.error('Error logging out: ', error);
  }
};

export const checkAuthState = (): AppThunk => async dispatch => {
  onAuthStateChanged(auth, async (user: FirebaseUser | null) =>  {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      dispatch(setUser({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        token: idTokenResult.token,
        role: idTokenResult.claims.role as string
      }));
    } else {
      dispatch(clearUser());
    }
  });
};
export const selectJwtToken = (state: RootState) => state.auth.user?.token

export default authSlice.reducer;
