import React, { useEffect } from 'react';
import DeviceForm from '../organisms/DeviceForm'
import DeviceList from '../organisms/DeviceList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState } from '../../redux/slices/authSlice';
import Spinner from 'react-bootstrap/Spinner';

const DevicesPage: React.FC = () => {

  const dispatch = useDispatch<any>()
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  if (!isAuthenticated) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div>
      <h1>Devices</h1>
      <DeviceForm />
      <DeviceList />
    </div>
  )
};

export default DevicesPage;
