import React from 'react';
import CheckboxField from '../atoms/CheckboxField';
import SelectField, { Option } from '../atoms/SelectField';
import InputField from '../atoms/InputField';
import { Configuration } from '../../types';
import Button from '../atoms/Button';

interface ConfigurationFormProps {
  configuration: Configuration;
  onChange: (key: string, value: any) => void;
  onSubmit: (customer: Configuration) => void;
}

const options: Option[] = [
  {value: "Please Select PiM"} , { value: 'Covetrus' }, { value: 'Shepard' }, { value: 'Vetspire' }, { value: 'Digitail' }
]

const ConfigurationForm: React.FC<ConfigurationFormProps> = ({ configuration, onChange, onSubmit }) => {
  const { pimsEnabled, integrationType, publicKey, installationKey, privateKey } = configuration;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(configuration);
  };

  return (
    <form className="mt-3" onSubmit={handleSubmit}>
      <CheckboxField
        id="PIMS Enabled"
        label="PIMS Enabled"
        checked={pimsEnabled}
        onChange={(e) => onChange('pimsEnabled', e.target.checked)}
      />
      <SelectField
        label="Integration Type"
        value={integrationType}
        options={options}
        onChange={(e) => onChange('integrationType', e.target.value)}
      />
      {integrationType === 'Covetrus' && (
        <>
          <InputField
            label="Private Key"
            value={privateKey || ''}
            placeholder="Enter Private Key"
            onChange={(e) => onChange('privateKey', e.target.value)}
          />
          <InputField
            label="Public Key"
            value={publicKey || ''}
            placeholder="Enter Public Key"
            onChange={(e) => onChange('publicKey', e.target.value)}
          />
          <InputField
            label="Installation Key"
            value={installationKey || ''}
            placeholder="Enter Installation Key"
            onChange={(e) => onChange('installationKey', e.target.value)}
          />
        </>
      )}
      {integrationType === 'Shepard' && (
        <>
          <InputField
            label="Private Key"
            value={privateKey || ''}
            placeholder="Enter Private Key"
            onChange={(e) => onChange('privateKey', e.target.value)}
          />
          <InputField
            label="Public Key"
            value={publicKey || ''}
            placeholder="Enter Public Key"
            onChange={(e) => onChange('publicKey', e.target.value)}
          />
        </>
      )}
      {integrationType === 'Vetspire' && (
        <InputField
          label="Private Key"
          value={privateKey || ''}
          placeholder="Enter Private Key"
          onChange={(e) => onChange('privateKey', e.target.value)}
        />
      )}
      {integrationType === 'Digitail' && (
        <>
          <InputField
            label="Private Key"
            value={privateKey || ''}
            placeholder="Enter Private Key"
            onChange={(e) => onChange('privateKey', e.target.value)}
          />
          <InputField
            label="Public Key"
            value={publicKey || ''}
            placeholder="Enter Public Key"
            onChange={(e) => onChange('publicKey', e.target.value)}
          />
        </>
      )}
      <Button type="submit">Update Configuration</Button>
    </form>
  );
};

export default ConfigurationForm;
