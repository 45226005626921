import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import NavLink from '../atoms/NavLink';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState } from '../../redux/slices/authSlice';

const NavLinksMolecule: React.FC = () => {
  const dispatch = useDispatch<any>()
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [ admin, setAdmin ]= useState<boolean>(false)
  const [ superAdmin, setSuperAdmin ] = useState<boolean>(false)
  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);
  useEffect(() => {
    if (user) {
      setSuperAdmin(user.role === 'super_admin')
      setAdmin(user.role === 'super_admin' || user.role === 'admin')
    }
  }, [user])
  return (
    <Nav className="me-auto">
      {isAuthenticated && <NavLink to="/dashboard" label="Dashboard" />}
      {isAuthenticated && superAdmin && <NavLink to="/partners" label="Partners" />}
      {isAuthenticated && admin && <NavLink to="/customers" label="Customers" />}
      {isAuthenticated && admin && <NavLink to="/devices" label="Devices" />}
      {isAuthenticated && <NavLink to="/patients" label="Patients" />}
      {isAuthenticated && <NavLink to="/sessions" label="Sessions" />}
      {isAuthenticated && <NavLink to="/vitalsboard" label="Vitals Board" />}
      {isAuthenticated && <NavLink to="/logout" label='Logout' />}
      {!isAuthenticated && <NavLink to="/login" label='Login' />}
    </Nav>
  );
};

export default NavLinksMolecule;
