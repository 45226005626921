import React, { useRef, useState, MouseEvent } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { selectRoomData } from '../../redux/slices/dataSlice';
import PatientInfo from '../molecules/PatientInfo';
import ValueDisplay from '../molecules/ValueDisplay';
import BadgeComponent from '../molecules/Badge';
import PatientCardHeader from '../molecules/PatientCardHeader';
import ContextMenu from './PatientContextMenu';
import AssignPatientModal from './AssignPatient.Modal';
import { Patient, Badge } from '../../types';
import { VitalsColorKeys } from '../../theme/colors';

import { AiOutlineDisconnect } from "react-icons/ai";
import { GiMovementSensor } from "react-icons/gi";


interface PatientCardProps {
  sessionId?: string;
  patient: Patient;
  badge: Badge;
  deviceId: string;
}

const StyledCard = styled(Card)`
  cursor: pointer;
  min-width: 18rem;
  background-color: #000;
  color: #fff;
`;

function getTimeFromISOString(isoString: string): string {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `Last Update: ${hours}:${minutes}:${seconds}`;
}

const PatientCard: React.FC<PatientCardProps> = ({ sessionId, patient, badge, deviceId }) => {
  const vitalsData = useSelector((state: RootState) => selectRoomData(state, deviceId));
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isSessionRunning, setIsSessionRunning] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const cardRef = useRef<HTMLDivElement>(null);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setContextMenu({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleAssignPatient = (patient: any) => {
    setIsAssigned(true);
    // Handle patient assignment logic here
  };

  const handleStartSession = () => {
    if (isAssigned) {
      setIsSessionRunning(true);
    }
    handleCloseContextMenu();
  };

  const handleEndSession = () => {
    if (isSessionRunning) {
      setIsSessionRunning(false);
      setIsAssigned(false);
    }
    handleCloseContextMenu();
  };

  const handleAssignClick = () => {
    setShowModal(true);
    handleCloseContextMenu();
  };
  const meta = vitalsData.meta;

  const hrColor:VitalsColorKeys = "neongreen"
  const rrColor:VitalsColorKeys  = "neonblue"
  const weighColor:VitalsColorKeys  = "neonyellow"
  const tempColor:VitalsColorKeys  = "white"
  return (
    <>
      <StyledCard onContextMenu={handleContextMenu} ref={cardRef}>
        <PatientCardHeader patientId={patient.id} color={badge.color} />
        <Card.Body>
          <Row>
            <Col xs>
              <PatientInfo
                name={patient.name}
                phone={patient.phone}
                doctor={patient.doctor}
                species={patient.species}
              />
            </Col>
            <Col xs lg="4">
              <BadgeComponent number={badge.number} color={badge.color} location={badge.location} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <ValueDisplay
                value={+vitalsData?.hr.toFixed(0)}
                unit="BPM"
                displayColor={hrColor}
                gt={meta.gt}
                gt_value={+vitalsData.gt.hr.toFixed(0)}
                gtValid={meta.shrValid}
                valid={meta.vhrValid}
              />
            </Col>
            <Col>
              <ValueDisplay
                value={+vitalsData?.rr.toFixed(0)}
                unit="RR"
                displayColor={rrColor}
                gt={meta.gt}
                gtValid={meta.frrValid}
                gt_value={+vitalsData.gt.rr.toFixed(0)}
                valid={meta.vrrValid}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <ValueDisplay
                value={+vitalsData?.temp.toFixed(1)}
                unit="°C"
                displayColor={tempColor}
                small={true}
                valid={vitalsData.validTemp}
              />
            </Col>
            <Col>
              <ValueDisplay
                value={+vitalsData?.weight.toFixed(1)}
                unit="kg"
                displayColor={weighColor}
                small={true}
                valid={vitalsData.weight > 2}
              />
            </Col>
          </Row>
        </Card.Body>
        {contextMenu && (
          <ContextMenu
            position={contextMenu}
            onClose={handleCloseContextMenu}
            onAssignClick={handleAssignClick}
            onStart={handleStartSession}
            onEnd={handleEndSession}
            isAssigned={isAssigned}
            isSessionRunning={isSessionRunning}
          />
        )}
        <Card.Footer style={{
          textAlign: "right",
          opacity: .5
        }}>
          { !vitalsData.meta.continuousData && <><AiOutlineDisconnect />&nbsp;</> }
          { !vitalsData.meta.weightSettled && <><GiMovementSensor />&nbsp;</> }
          {(vitalsData?.ts) ? getTimeFromISOString(vitalsData?.ts + "") : "--"}</Card.Footer>
      </StyledCard>
      <AssignPatientModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onAssign={handleAssignPatient}
      />
    </>
  );
};

export default PatientCard;
