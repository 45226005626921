import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <h3>Welcome to Brisby's Console App</h3>
            </div>
            <div className="card-body">
              <p className="lead">
                Disclaimer: You must have an account to use this web application.
              </p>
              <p>
                If you already have an account, please <Link to="/login">log in here</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
