export interface Colors {
  [key: string]: string;
}

export interface PadColerElement {
  subtext: string;
  bg: string;
}

export type PadColorKeys = 'red' | 'pink' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple';

export const padColors: Record<PadColorKeys, PadColerElement> = {
  red: {
    subtext: '#FF5F5B',
    bg: '#FFD5D5'
  },
  pink: {
    subtext: '#7E70B9',
    bg: '#F7B3D6'
  },
  orange: {
    subtext: '#FF9960',
    bg: '#F7D3B3'
  },
  yellow: {
    subtext: '#EAD560',
    bg: '#FFF9D9'
  },
  green: {
    subtext: '#B4E77F',
    bg: '#DFFFE2'
  },
  blue: {
    subtext: '#0C95CF',
    bg: '#B2E8FF'
  },
  purple: {
    subtext: '#5D23FD',
    bg: '#E6DDFF'
  }
};



export type VitalsColorKeys = 'neongreen' | 'neonblue' | 'neonyellow' | 'white' | 'grey';

export const vitalColors: Record<VitalsColorKeys, string> = {
  neongreen: "var(--brisby-green-neon, #0CF268)",
  neonblue: "var(--brisby-neon-blue, #0FF)",
  neonyellow: "var(--brisby-yellow-neon, #FFF500)",
  white: "var(--brisby-white, #FFFFFD)",
  grey: "var(--brisby-white, #B4A6AB)"
};
