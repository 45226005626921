const protocol = process.env.REACT_APP_PROTOCOL;
const domain = process.env.REACT_APP_DOMAIN;

if (!protocol || !domain) {
  throw new Error('REACT_APP_PROTOCOL and REACT_APP_DOMAIN must be set in the .env file');
}

export function combinePathWithDomain(path: string): string {
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return `${protocol}://${domain}${path}`;
}