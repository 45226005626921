import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState } from '../../redux/slices/authSlice';
import { Badge, Patient, Session, Sex, Device } from '../../types';
import PatientCard from '../organisms/PatientCard';
import { fetchDevicesWithVitals, selectDevices, selectDevicesLoading, selectDevicesError } from '../../redux/slices/devices';
import websocketService from '../../services/websocketService';
import Dropdown from 'react-bootstrap/Dropdown';

const ALL = "all";

const defaultSession: Session = {
  id: "",
  startTime: ""
}

const defaultPatient: Patient = {
  id: "",
  customerId: "--",
  species: '--',
  breed: 'house',
  name: "--",
  sex: Sex.FEMALE_SPAYED,
  doctor: '--',
  phone: '--',
}
const defaultBadge: Badge = {
  color: "pink",
  number: "--",
  location: "--"
}
const VitalsBoardPage: React.FC = () => {
  const dispatch = useDispatch<any>()
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const devices = useSelector(selectDevices);
  const loading = useSelector(selectDevicesLoading);
  const error = useSelector(selectDevicesError);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedLocation, setLocation] = useState<undefined | string>(ALL)

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchDevicesWithVitals());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && devices.length < 1 && !user) {
      return;
    }
    if (user && !user.token) {
      return;
    }
    const jwt = user?.token || "--"
    const roomIds = devices.map((device) => device.id)
    websocketService.connect(jwt);

    roomIds.forEach(roomId => {
      websocketService.joinRoom(roomId);
    });

    return () => {
      websocketService.disconnect();
    };
  }, [isAuthenticated, user, devices]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (selectedDevices.length > 0) {
      return;
    }
    setSelectedDevices(devices)

  }, [isAuthenticated, devices, selectedDevices])

  useEffect(() => {
    if (selectedLocation === ALL) {
      setSelectedDevices(devices)
      return;
    }
    const choosenDevices = devices.filter((device) => {
      return device.inClinicLocation?.toLocaleLowerCase() === selectedLocation?.toLocaleLowerCase()
    })
    setSelectedDevices(choosenDevices)

  }, [selectedLocation])


  if (!isAuthenticated) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const locations = [ALL, ...new Set(devices.map(device => (device.inClinicLocation || "--")))]
  return (
    <>
      <br />
      <Row>

        <Dropdown>
          <Dropdown.Toggle variant="light" >
            Show Beds [{selectedLocation}]
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.ItemText>Select a Location</Dropdown.ItemText>
            {locations.map((location, index) => {
              return <Dropdown.Item as="button" key={`locations-${index}`} onClick={() => {
                setLocation(location)
              }}>{location}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Row>
      <br />
      <Row>
        {selectedDevices.map((device) => {
          const session = device?.session || {...defaultSession}
          const aPatient = session?.patient || {...defaultPatient};
          const aBadge = {...defaultBadge};
          if (device.colorCode) {
            aBadge.color = device.colorCode;
            aBadge.location = device.colorCode;
          }
          if (device.inClinicLocation) {
            aBadge.location = device.inClinicLocation;
          }
          if (device.name) {
            aBadge.location = device.name;
          }
          if (device.inClinicLocationNumber) {
            aBadge.number = `${device.inClinicLocationNumber}`;
          }
          return (
            <React.Fragment key={device.id}>
              <Col md={4} className="mb-4">
                <PatientCard
                  sessionId={session.id}
                  patient={aPatient}
                  badge={aBadge}
                  deviceId={device.id}
                />
              </Col>
            </React.Fragment>
          )
        })}
      </Row></>
  );
};

export default VitalsBoardPage;
