import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

interface NavLinkAtomProps {
    to: string;
    label: string;
}

const NavLinkAtom: React.FC<NavLinkAtomProps> = ({ to, label }) => {
    return (
        <Nav.Link as={NavLink} to={to}>
            {label}
        </Nav.Link>
    );
};

export default NavLinkAtom;
