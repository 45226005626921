import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState, logout } from '../../redux/slices/authSlice';

const DashboardPage: React.FC = () => {
  const dispatch = useDispatch<any>()
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  if (!isAuthenticated) {
    return <div>Please log in to access the dashboard.</div>;
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <div>Welcome, {user?.displayName || user?.email}</div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default DashboardPage;
