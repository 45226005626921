import React from 'react';
import { Customer } from '../../types';
import Button from '../atoms/Button';

interface CustomerListProps {
    customers: Customer[];
    onDelete: (id: string) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({ customers, onDelete }) => {
    return (
        <div>
            <h2>Customers</h2>
            <ul>
                {customers.map((customer: Customer) => (
                    <li key={customer.id}>
                        {customer.name}&nbsp;
                        <Button href={`/edit-customer/${customer.id}`}>Edit</Button>&nbsp;
                        <Button onClick={() => onDelete(customer.id)}>Delete</Button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomerList;
