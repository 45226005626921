import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Customer } from '../../types';
import { fetchWithJwt } from '../../utils/fetchWithJwt';

interface CustomerState {
    customers: Customer[];
    loading: boolean;
    error: string | null;
}

const initialState: CustomerState = {
    customers: [],
    loading: false,
    error: null,
};

// Async thunk for fetching a customer by ID
export const fetchCustomerById = createAsyncThunk(
    'customers/fetchCustomerById',
    async (customerId: string, { rejectWithValue }) => {
        try {
            const response = await fetchWithJwt(`/customers/${customerId}`);
            const data: Customer = await response.json();
            return data;
        } catch (error:any) {
            return rejectWithValue(error.toString());
        }
    }
);

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        fetchCustomersStart(state) {
            state.loading = true;
        },
        fetchCustomersSuccess(state, action: PayloadAction<Customer[]>) {
            state.loading = false;
            state.customers = action.payload;
        },
        fetchCustomersFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addCustomerSuccess(state, action: PayloadAction<Customer>) {
            state.customers.push(action.payload);
        },
        updateCustomerSuccess(state, action: PayloadAction<Customer>) {
            const index = state.customers.findIndex(customer => customer.id === action.payload.id);
            if (index !== -1) {
                state.customers[index] = action.payload;
            }
        },
        deleteCustomerSuccess(state, action: PayloadAction<string>) {
            state.customers = state.customers.filter(customer => customer.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomerById.fulfilled, (state, action: PayloadAction<Customer>) => {
                state.loading = false;
                const index = state.customers.findIndex(customer => customer.id === action.payload.id);
                if (index !== -1) {
                    state.customers[index] = action.payload;
                } else {
                    state.customers.push(action.payload);
                }
            })
            .addCase(fetchCustomerById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const {
    fetchCustomersStart,
    fetchCustomersSuccess,
    fetchCustomersFailure,
    addCustomerSuccess,
    updateCustomerSuccess,
    deleteCustomerSuccess,
} = customerSlice.actions;

export const fetchCustomers = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchCustomersStart());
        const response = await fetchWithJwt('/customers');
        const data: Customer[] = await response.json();
        dispatch(fetchCustomersSuccess(data));
    } catch (error:any) {
        dispatch(fetchCustomersFailure(error.toString()));
    }
};

export const addCustomer = (customer: Customer): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt('/customers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        });
        const data: Customer = await response.json();
        dispatch(addCustomerSuccess(data));
    } catch (error:any) {
        dispatch(fetchCustomersFailure(error.toString()));
    }
};

export const updateCustomer = (customer: Customer): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt(`/customers/${customer.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        });
        const data: Customer = await response.json();
        dispatch(updateCustomerSuccess(data));
    } catch (error:any) {
        dispatch(fetchCustomersFailure(error.toString()));
    }
};

export const deleteCustomer = (id: string): AppThunk => async dispatch => {
    try {
        await fetchWithJwt(`/customers/${id}`, {
            method: 'DELETE',
        });
        dispatch(deleteCustomerSuccess(id));
    } catch (error:any) {
        dispatch(fetchCustomersFailure(error.toString()));
    }
};

export const selectCustomers = (state: RootState) => state.customers.customers;
export const selectCustomersLoading = (state: RootState) => state.customers.loading;
export const selectCustomersError = (state: RootState) => state.customers.error;
export const selectCustomerById = (state: RootState, customerId: string) => 
    state.customers.customers.find(customer => customer.id === customerId);

export default customerSlice.reducer;
