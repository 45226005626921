import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients, addPatient, updatePatient, deletePatient, selectPatients } from '../../redux/slices/patients';
import { fetchCustomers, selectCustomers } from '../../redux/slices/customers';
import PatientForm from '../organisms/PatientsForm';
import PatientList from '../organisms/PatientsList';
import { Patient } from '../../types';
import { RootState } from '../../redux/rootReducer'
import { checkAuthState } from '../../redux/slices/authSlice';

const PatientsPage: React.FC = () => {
    const dispatch = useDispatch<any>();
    const patients = useSelector(selectPatients);
    const customers = useSelector(selectCustomers);
    const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

    useEffect(() => {
        dispatch(fetchPatients());
        dispatch(fetchCustomers());
    }, [dispatch, isAuthenticated]);

    const handleAddOrUpdatePatient = (patient: Patient) => {
        if (patient.id) {
            dispatch(updatePatient(patient));
        } else {
            dispatch(addPatient(patient));
        }
    };

    const handleDeletePatient = (id: string) => {
        dispatch(deletePatient(id));
    };

    return (
        <div>
            <h1>Patients</h1>
            <PatientForm customers={customers} onSubmit={handleAddOrUpdatePatient} />
            <PatientList patients={patients} onDelete={handleDeletePatient} />
        </div>
    );
};

export default PatientsPage;
