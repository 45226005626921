import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logger from './utils/logger';
logger.configure({
  auth: true,
  api: true,
  ui: false,
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

reportWebVitals();
