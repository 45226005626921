import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Patient } from '../../types';
import { fetchWithJwt } from '../../utils/fetchWithJwt';
import { copyWithoutId } from '../../utils/copyWithoutId';

interface PatientState {
    patients: Patient[];
    loading: boolean;
    error: string | null;
}

const initialState: PatientState = {
    patients: [],
    loading: false,
    error: null,
};

const patientSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        fetchPatientsStart(state) {
            state.loading = true;
        },
        fetchPatientsSuccess(state, action: PayloadAction<Patient[]>) {
            state.loading = false;
            state.patients = action.payload;
        },
        fetchPatientsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addPatientSuccess(state, action: PayloadAction<Patient>) {
            state.patients.push(action.payload);
        },
        updatePatientSuccess(state, action: PayloadAction<Patient>) {
            const index = state.patients.findIndex(patient => patient.id === action.payload.id);
            if (index !== -1) {
                state.patients[index] = action.payload;
            }
        },
        deletePatientSuccess(state, action: PayloadAction<string>) {
            state.patients = state.patients.filter(patient => patient.id !== action.payload);
        },
    },
});

export const {
    fetchPatientsStart,
    fetchPatientsSuccess,
    fetchPatientsFailure,
    addPatientSuccess,
    updatePatientSuccess,
    deletePatientSuccess,
} = patientSlice.actions;

export const fetchPatients = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchPatientsStart());
        const response = await fetchWithJwt('/patients');
        const data: Patient[] = await response.json();
        dispatch(fetchPatientsSuccess(data));
    } catch (error:any) {
        dispatch(fetchPatientsFailure(error.toString()));
    }
};

export const addPatient = (patient: Patient): AppThunk => async dispatch => {
  
    try {
        const response = await fetchWithJwt('/patients', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(copyWithoutId(patient)),
        });
        const data: Patient = await response.json();
        dispatch(addPatientSuccess(data));
    } catch (error:any) {
        dispatch(fetchPatientsFailure(error.toString()));
    }
};

export const updatePatient = (patient: Patient): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt(`/patients/${patient.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(patient),
        });
        const data: Patient = await response.json();
        dispatch(updatePatientSuccess(data));
    } catch (error:any) {
        dispatch(fetchPatientsFailure(error.toString()));
    }
};

export const deletePatient = (id: string): AppThunk => async dispatch => {
    try {
        await fetchWithJwt(`/patients/${id}`, {
            method: 'DELETE',
        });
        dispatch(deletePatientSuccess(id));
    } catch (error:any) {
        dispatch(fetchPatientsFailure(error.toString()));
    }
};

export const selectPatients = (state: RootState) => state.patients.patients;
export const selectPatientsLoading = (state: RootState) => state.patients.loading;
export const selectPatientsError = (state: RootState) => state.patients.error;

export default patientSlice.reducer;
