import React from 'react';
import { Session } from '../../types';
import Button from '../atoms/Button';

interface SessionListProps {
    sessions: Session[];
    onEnd: (id: string) => void;
}

const SessionList: React.FC<SessionListProps> = ({ sessions, onEnd }) => {
    return (
        <div>
            <h2>Sessions</h2>
            <ul>
                {sessions.map((session: Session) => (
                    <li key={session.id}>
                        {`Patient ID: ${session.patientId}, Device ID: ${session.deviceId}, Start Time: ${session.startTime}, End Time: ${session.endTime || 'Ongoing'}`}
                        {!session.endTime && <Button onClick={() => onEnd(session.id)}>End Session</Button>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SessionList;
