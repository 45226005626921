import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/slices/authSlice'

const Logout: React.FC = () => {
  const dispatch = useDispatch<any>();
  const history = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      await dispatch(logout());
      history('/login');
    };

    performLogout();
  }, [dispatch, history]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
