import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, addCustomer, updateCustomer, deleteCustomer, selectCustomers } from '../../redux/slices/customers';
import CustomerForm from '../organisms/CustomerForm';
import CustomerList from '../organisms/CustomerList';
import { RootState } from '../../redux/rootReducer'
import { checkAuthState } from '../../redux/slices/authSlice';
import { Customer } from '../../types';

const CustomersPage: React.FC = () => {
  const dispatch = useDispatch<any>()
  const customers = useSelector(selectCustomers);
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCustomers());
    }
  }, [dispatch, isAuthenticated]);


  const handleAddOrUpdateCustomer = (customer: Customer) => {
    if (customer.id) {
      dispatch(updateCustomer(customer));
    } else {
      dispatch(addCustomer(customer));
    }
  };

  const handleDeleteCustomer = (id: string) => {
    dispatch(deleteCustomer(id));
  };

  return (
    <div>
      <h1>Customers</h1>
      <CustomerForm onSubmit={handleAddOrUpdateCustomer} />
      <CustomerList customers={customers} onDelete={handleDeleteCustomer} />
    </div>
  );
};

export default CustomersPage;
