import React from 'react';
import PartnerList from '../organisms/PartnersList';
import PartnerForm from '../organisms/PartnersForm';

const PartnersPage: React.FC = () => {
  return (
    <div>
      <h1>Partners</h1>
      <PartnerForm />
      <PartnerList />
    </div>
  );
};

export default PartnersPage;
