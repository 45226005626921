import React from 'react';
import { Toast, ToastContainer as BootstrapToastContainer } from 'react-bootstrap';
import { useToast } from '../../utils/ToastContext';

const ToastContainer: React.FC = () => {
    const { toasts } = useToast();

    return (
        <BootstrapToastContainer position="top-end" className="p-3">
            {toasts.map(toast => (
                <Toast key={toast.id} bg={toast.variant} autohide delay={10000}>
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toast.message}</Toast.Body>
                </Toast>
            ))}
        </BootstrapToastContainer>
    );
};

export default ToastContainer;