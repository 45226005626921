import React, { useState } from 'react';
import FormField from '../molecules/FormField';
import Button from '../atoms/Button';
import { Session, Patient, Device } from '../../types';

interface SessionFormProps {
    patients: Patient[];
    devices: Device[];
    onSubmit: (session: Omit<Session, 'id' | 'startTime' | 'endTime'>) => void;
}

const SessionForm: React.FC<SessionFormProps> = ({ patients, devices, onSubmit }) => {
    const [patientId, setPatientId] = useState('');
    const [deviceId, setDeviceId] = useState('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const sessionData = { patientId, deviceId };
        onSubmit(sessionData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormField
                id="patientId"
                label="Patient"
                type="select"
                value={patientId}
                onChange={(e) => setPatientId(e.target.value)}
                options={patients.map((patient: Patient) => ({
                    value: patient.id,
                    label: patient.name,
                }))}
                placeholder="Select a patient"
            />
            <FormField
                id="deviceId"
                label="Device"
                type="select"
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                options={devices.map((device: Device) => ({
                    value: device.id,
                    label: device.serialNumber,
                }))}
                placeholder="Select a device"
            />
            <Button type="submit">Start Session</Button>
        </form>
    );
};

export default SessionForm;
