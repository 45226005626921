// context/ToastContext.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode, FC } from 'react';

interface Toast {
    id: number;
    message: string;
    variant: 'success' | 'danger' | 'warning' | 'info';
}

interface ToastContextProps {
    toasts: Toast[];
    addToast: (message: string, variant: Toast['variant']) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

interface ToastProviderProps {
    children: ReactNode;
}

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = useCallback((message: string, variant: Toast['variant']) => {
        const id = Date.now();
        setToasts(prevToasts => [...prevToasts, { id, message, variant }]);

        setTimeout(() => {
            setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
        }, 10000); // Toast will disappear after 10 seconds
    }, []);

    return (
        <ToastContext.Provider value={{ toasts, addToast }}>
            {children}
        </ToastContext.Provider>
    );
};

const useToast = (): ToastContextProps => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

export { ToastProvider, useToast };
