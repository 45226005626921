import React from 'react';
import Header from 'react-bootstrap/CardHeader'
import { PadColerElement, PadColorKeys, padColors } from '../../theme/colors';
import styled from 'styled-components';

interface PatientCardProps {
  patientId: string;
  color: PadColorKeys | string
}

function getPadColor(key: PadColorKeys): PadColerElement {
  return padColors[key];
}

interface CardHeaderProps {
  bgColor?: string;
}

const CardHeader = styled(Header) <CardHeaderProps>`
  font-size: 1.0em;
  color: #000000;
  padding: 0;
  background-color: ${props => props.bgColor} !important;
`;


const PatientCardHeader: React.FC<PatientCardProps> = ({ color, patientId }) => {
  const colorPallet = getPadColor(color as PadColorKeys);
  return (
      <CardHeader className='text-center' bgColor={colorPallet.bg}>{patientId}</CardHeader>
  );
};

export default PatientCardHeader;
