import React, { useState } from 'react';
import FormField from '../molecules/FormField';
import Button from '../atoms/Button';
import { Customer } from '../../types';

interface CustomerFormProps {
    customer?: Customer;
    onSubmit: (customer: Customer) => void;
}

const CustomerForm: React.FC<CustomerFormProps> = ({ customer, onSubmit }) => {
    const [name, setName] = useState(customer?.name || '');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const customerData: Customer = { id: customer?.id || '', name };
        onSubmit(customerData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormField
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter customer name"
            />
            <Button type="submit">{customer ? 'Update' : 'Add'} Customer</Button>
        </form>
    );
};

export default CustomerForm;
