import React from 'react';
import { Patient } from '../../types';
import Button from '../atoms/Button';

interface PatientListProps {
    patients: Patient[];
    onDelete: (id: string) => void;
}

const PatientList: React.FC<PatientListProps> = ({ patients, onDelete }) => {
    return (
        <div>
            <h2>Patients</h2>
            <ul>
                {patients.map((patient: Patient) => (
                    <li key={patient.id}>
                        {patient.name} ({patient.species}, {patient.breed}) - Customer ID: {patient.customerId}
                        <Button onClick={() => onDelete(patient.id)}>Delete</Button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PatientList;
