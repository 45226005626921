import React from 'react';
import { Partner } from '../../types';
import Button from '../atoms/Button';

interface PartnerItemProps {
  partner: Partner;
  onEdit: () => void;
  onDelete: () => void;
}

const PartnerItem: React.FC<PartnerItemProps> = ({ partner, onEdit, onDelete }) => {
  return (
    <li>
      {partner.name}
      <Button onClick={onEdit}>Edit</Button>
      <Button onClick={onDelete}>Delete</Button>
    </li>
  );
};

export default PartnerItem;
