import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';

interface ContextMenuProps {
  position: { x: number; y: number };
  onClose: () => void;
  onAssignClick: () => void;
  onStart: () => void;
  onEnd: () => void;
  isAssigned: boolean;
  isSessionRunning: boolean;
}

const StyledContextMenu = styled.div`
  position: absolute;
  z-index: 1000;
`;

const ContextMenu: React.FC<ContextMenuProps> = ({
  position,
  onClose,
  onAssignClick,
  onStart,
  onEnd,
  isAssigned,
  isSessionRunning,
}) => {
  return (
    <StyledContextMenu style={{ top: position.y, left: position.x }} onMouseLeave={onClose}>
      <DropdownButton id="dropdown-basic-button" title="Actions" show>
        <Dropdown.Item onClick={onAssignClick} disabled={isAssigned}>
          Assign Patient
        </Dropdown.Item>
        <Dropdown.Item onClick={onStart} disabled={!isAssigned || isSessionRunning}>
          Start Session
        </Dropdown.Item>
        <Dropdown.Item onClick={onEnd} disabled={!isSessionRunning}>
          End Session
        </Dropdown.Item>
      </DropdownButton>
    </StyledContextMenu>
  );
};

export default ContextMenu;
