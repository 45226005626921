import React from 'react';
import styled from 'styled-components';

interface PatientInfoProps {
  species?: string
  name?: string;
  phone?: string;
  doctor?: string;
}

const StyledDiv = styled.div`
  font-size: 1em;
  @media (min-width: 576px) {
    font-size: 1em;
  }
  @media (min-width: 768px) {
    font-size: 1em;
  }
  @media (min-width: 992px) {
    font-size: 1em;
  }
  @media (min-width: 1200px) {
    font-size: 1.25em;
  }
  @media (min-width: 1400px) {
    font-size: 1.5em;
  }
`

const PatientInfo: React.FC<PatientInfoProps> = ({name="--", phone="--", doctor="--", species="--" }) => (
  <StyledDiv>
    <div>
      <strong>{name}</strong> ({species})<br />
      {phone}<br />
      {doctor}
    </div>
  </StyledDiv>
);

export default PatientInfo;
