import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import NavBar from './components/organisms/NavBar';
import AppRoutes from './App.Routes';
import { ToastProvider } from './utils/ToastContext';
import ToastContainer from './components/organisms/ToastContainer';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastProvider>
        <ToastContainer />
        <Router>
          <NavBar />
          <AppRoutes />
        </Router>
      </ToastProvider>
    </Provider>
  );
};

export default App;
