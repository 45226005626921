import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSessions, addSession, endSession, selectSessions } from '../../redux/slices/sessions';
import { fetchPatients, selectPatients } from '../../redux/slices/patients';
import { fetchDevices, selectDevices } from '../../redux/slices/devices';
import SessionForm from '../organisms/SessionForm';
import SessionList from '../organisms/SessionList';
import { Session } from '../../types';
import { RootState } from '../../redux/rootReducer'
import { checkAuthState } from '../../redux/slices/authSlice';

const SessionsPage: React.FC = () => {
    const dispatch = useDispatch<any>();
    const sessions = useSelector(selectSessions);
    const patients = useSelector(selectPatients);
    const devices = useSelector(selectDevices);
    const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
      dispatch(checkAuthState());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchSessions());
        dispatch(fetchPatients());
        dispatch(fetchDevices());
    }, [dispatch, isAuthenticated]);

    const handleAddSession = (session: Omit<Session, 'id' | 'startTime' | 'endTime'>) => {
        dispatch(addSession(session));
    };

    const handleEndSession = (id: string) => {
        dispatch(endSession(id));
    };

    return (
        <div>
            <h1>Sessions</h1>
            <SessionForm patients={patients} devices={devices} onSubmit={handleAddSession} />
            <SessionList sessions={sessions} onEnd={handleEndSession} />
        </div>
    );
};

export default SessionsPage;
