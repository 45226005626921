import { combinePathWithDomain } from './combinePathWithDomain';
import { getJwtToken } from './getJwtToken';
import logger from './logger';

export const fetchWithJwt = async (path: string, options: RequestInit = {}): Promise<Response> => {
  const token = await getJwtToken();
  if (!token) {
    throw new Error('JWT token is missing');
  }

  const headers = new Headers(options.headers || {});
  headers.set('Authorization', `Bearer ${token}`);

  const newOptions: RequestInit = {
    ...options,
    headers,
  };

  const url = combinePathWithDomain(path);
  const response = await fetch(url, newOptions);
  logger.info('api', url);
  logger.info('api', response);
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response;
};
