import { combineReducers } from '@reduxjs/toolkit';
import partnersReducer from './slices/partners';
import authReducer from './slices/authSlice';
import customersReducer from './slices/customers';
import devices from './slices/devices';
import patients from './slices/patients';
import sessions from './slices/sessions'
import data from './slices/dataSlice'

const rootReducer = combineReducers({
  partners: partnersReducer,
  auth: authReducer,
  customers: customersReducer,
  devices,
  patients,
  sessions,
  data
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
