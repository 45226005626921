import React, { useState } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

interface AssignPatientModalProps {
  show: boolean;
  onHide: () => void;
  onAssign: (patient: any) => void;
}

const patientsData = [
  { id: 1, name: 'John Doe', phone: '123-456-7890', details: 'Patient details 1' },
  { id: 2, name: 'Jane Smith', phone: '987-654-3210', details: 'Patient details 2' },
  // Add more mock patients here
];

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #000;
    color: #fff;
  }
`;

const AssignPatientModal: React.FC<AssignPatientModalProps> = ({ show, onHide, onAssign }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<any | null>(null);

  const filteredPatients = patientsData.filter(patient =>
    patient.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectedPatient(null);
  };

  const handlePatientSelect = (patient: any) => {
    setSelectedPatient(patient);
  };

  const handleAssignPatient = () => {
    if (selectedPatient) {
      onAssign(selectedPatient);
      onHide();
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="patientSearch">
            <Form.Label>Search Patient by Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter patient name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Form.Group>
          {filteredPatients.length > 0 && (
            <ListGroup>
              {filteredPatients.map(patient => (
                <ListGroup.Item
                  key={patient.id}
                  onClick={() => handlePatientSelect(patient)}
                  active={selectedPatient?.id === patient.id}
                  style={{ cursor: 'pointer' }}
                >
                  {patient.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          {selectedPatient && (
            <div>
              <h5 className="mt-3">Patient Details</h5>
              <p>Name: {selectedPatient.name}</p>
              <p>Phone: {selectedPatient.phone}</p>
              <p>Details: {selectedPatient.details}</p>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAssignPatient} disabled={!selectedPatient}>
          Assign
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default AssignPatientModal;
