import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Device } from '../../types';
import { fetchWithJwt } from '../../utils/fetchWithJwt';
import { copyWithoutId } from '../../utils/copyWithoutId';

interface DeviceState {
    devices: Device[];
    loading: boolean;
    error: string | null;
}

const initialState: DeviceState = {
    devices: [],
    loading: false,
    error: null,
};

const deviceSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        fetchDevicesStart(state) {
            state.loading = true;
        },
        fetchDevicesSuccess(state, action: PayloadAction<Device[]>) {
            state.loading = false;
            state.devices = action.payload;
        },
        fetchDevicesFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addDeviceSuccess(state, action: PayloadAction<Device>) {
            state.devices.push(action.payload);
        },
        updateDeviceSuccess(state, action: PayloadAction<Device>) {
            const index = state.devices.findIndex(device => device.id === action.payload.id);
            if (index !== -1) {
                state.devices[index] = action.payload;
            }
        },
        deleteDeviceSuccess(state, action: PayloadAction<string>) {
            state.devices = state.devices.filter(device => device.id !== action.payload);
        },
    },
});

export const {
    fetchDevicesStart,
    fetchDevicesSuccess,
    fetchDevicesFailure,
    addDeviceSuccess,
    updateDeviceSuccess,
    deleteDeviceSuccess,
} = deviceSlice.actions;

export const fetchDevices = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchDevicesStart());
        const response = await fetchWithJwt('/devices');
        const data: Device[] = await response.json();
        dispatch(fetchDevicesSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const fetchDevicesWithVitals = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchDevicesStart());
        const response = await fetchWithJwt('/devices/vitalboard');
        const data: Device[] = await response.json();
        dispatch(fetchDevicesSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const addDevice = (device: Device): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt('/devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(copyWithoutId(device)),
        });
        const data: Device = await response.json();
        dispatch(addDeviceSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const updateDevice = (device: Device): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt(`/devices/${device.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        });
        const data: Device = await response.json();
        dispatch(updateDeviceSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const deleteDevice = (id: string): AppThunk => async dispatch => {
    try {
        await fetchWithJwt(`/devices/${id}`, {
            method: 'DELETE',
        });
        dispatch(deleteDeviceSuccess(id));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const selectDevices = (state: RootState) => state.devices.devices;
export const selectDevicesLoading = (state: RootState) => state.devices.loading;
export const selectDevicesError = (state: RootState) => state.devices.error;

export default deviceSlice.reducer;
