import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDevice, selectDevicesLoading, selectDevicesError } from '../../redux/slices/devices';
import { Device } from '../../types';
import FormField from '../molecules/FormField';
import CheckboxField from '../atoms/CheckboxField';

const DeviceForm: React.FC = () => {
  const dispatch = useDispatch<any>();
  const loading = useSelector(selectDevicesLoading);
  const error = useSelector(selectDevicesError);
  
  const [productLine, setproductLine] = useState('');
  const [modelNumber, setmodelNumber] = useState('');
  const [hardwareRevision, sethardwareRevision] = useState('');
  const [whiteLabelPartner, setwhiteLabelPartner] = useState('');
  const [customerId, setcustomerId] = useState('');


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      productLine,
      modelNumber,
      hardwareRevision,
      whiteLabelPartner,
      customerId,
    }
    dispatch(addDevice(formData as Device));
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormField id="productLine" label="Product Line" type="text" value={productLine} onChange={(e) => setproductLine(e.target.value)} />
      <FormField id="modelNumber" label="Model Number" type="text" value={modelNumber} onChange={(e) => setmodelNumber(e.target.value)} />
      <FormField id="hardwareRevision" label="Hardware Revision" type="text" value={hardwareRevision} onChange={(e) => sethardwareRevision(e.target.value)} />
      <FormField id="whiteLabelPartner" label="White Label Partner" type="text" value={whiteLabelPartner} onChange={(e) => setwhiteLabelPartner(e.target.value)} />
      <FormField id="customerId" label="Customer ID" type="text" value={customerId} onChange={(e) => setcustomerId(e.target.value)} />
      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? 'Creating...' : 'Create Device'}
      </button>
      {error && <div className="alert alert-danger">{error}</div>}
    </form>
  );
};

export default DeviceForm;
