import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Partners from './components/pages/Partners';
import Customers from './components/pages/Customers';
import Devices from './components/pages/Devices'
import Patients from './components/pages/Patient'
import Sessions from './components/pages/Sessions'
import VitalsBoard from './components/pages/VitalsBoard';
import Logout from './components/pages/Logout';
import NotFoundPage from './components/pages/NotFoundPage';
import ErrorBoundary from './components/pages/ErrorBoundary';
import EditCustomerPage from './components/pages/EditCustomerPage';

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const isVitalsBoard = location.pathname === '/vitalsboard';

  return (
    <ErrorBoundary>
      <Container fluid={isVitalsBoard}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/edit-customer/:id" element={<EditCustomerPage />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/vitalsboard" element={<VitalsBoard />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Home />} />
          <Route element={<NotFoundPage />} />
        </Routes>
      </Container>
    </ErrorBoundary>
  );
};

export default AppRoutes;
