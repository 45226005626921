import React, { useState } from 'react';
import FormField from '../molecules/FormField';
import Button from '../atoms/Button';
import { Patient, Customer, Sex } from '../../types';

interface PatientFormProps {
    patient?: Patient;
    customers: Customer[];
    onSubmit: (patient: Patient) => void;
}

const PatientForm: React.FC<PatientFormProps> = ({ patient, customers, onSubmit }) => {
    const [customerId, setCustomerId] = useState(patient?.customerId || '');
    const [species, setSpecies] = useState(patient?.species || 'dog');
    const [breed, setBreed] = useState(patient?.breed || '');
    const [name, setName] = useState(patient?.name || '');
    const [sex, setSex] = useState<Sex>(patient?.sex || Sex.MALE_INTACT);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const patientData: Patient = { id: patient?.id || '', customerId, species, breed, name, sex };
        onSubmit(patientData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormField
                id="customerId"
                label="Customer"
                type="select"
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                options={customers.map((customer: Customer) => ({
                    value: customer.id,
                    label: customer.name,
                }))}
                placeholder="Select a customer"
            />
            <FormField
                id="species"
                label="Species"
                type="select"
                value={species}
                onChange={(e) => setSpecies(e.target.value as 'dog' | 'cat')}
                options={[
                    { value: 'dog', label: 'Dog' },
                    { value: 'cat', label: 'Cat' },
                ]}
                placeholder="Select species"
            />
            <FormField
                id="breed"
                label="Breed"
                type="text"
                value={breed}
                onChange={(e) => setBreed(e.target.value)}
                placeholder="Enter breed"
            />
            <FormField
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
            />
            <FormField
                id="sex"
                label="Sex"
                type="select"
                value={sex}
                onChange={(e) => setSex(e.target.value as Sex)}
                options={Object.values(Sex).map(sex => ({
                    value: sex,
                    label: sex,
                }))}
                placeholder="Select sex"
            />
            <Button type="submit">{patient ? 'Update' : 'Add'} Patient</Button>
        </form>
    );
};

export default PatientForm;
