import React, { useEffect, useState } from 'react';
import { useParams, redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomerForm from '../organisms/CustomerForm';
import { fetchCustomerById, selectCustomerById, selectCustomersLoading, selectCustomersError } from '../../redux/slices/customers';
import { RootState } from '../../redux/rootReducer';
import ConfigurationForm from '../molecules/ConfigurationForm';
import { Configuration, Integrations } from '../../types';
import {useToast} from '../../utils/ToastContext';


const EditCustomerPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();  // Allow id to be optional
  const { addToast } = useToast();
  const dispatch = useDispatch<any>();
  const loading = useSelector(selectCustomersLoading);
  const error = useSelector(selectCustomersError);
  const customer = useSelector((state: RootState) => id ? selectCustomerById(state, id) : undefined);
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);

  const [configuration, setConfig] = useState<Configuration>(
    { pimsEnabled: false, integrationType: Integrations.NONE, privateKey: undefined, publicKey: undefined, installationKey: undefined }
  )

  useEffect(() => {
    if (!id) {
      redirect('/error'); // Redirect to an error page or handle invalid ID
    } else if (isAuthenticated) {
      dispatch(fetchCustomerById(id)); // Fetch customer if not already loaded
    }
  }, [id, dispatch, isAuthenticated]);

  useEffect(() => {
    if(customer && customer.configuration){
      setConfig(customer.configuration)
    }
  }, [customer])

  if (loading) {
    return <div>Loading...</div>;
  }


  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!customer) {
    return <div>Customer not found</div>;
  }

  return (
    <div>
      <h1>Edit Customer</h1>
      <CustomerForm customer={customer} onSubmit={() => { 
        addToast("Updated customer", "success")
      }} />
      <ConfigurationForm configuration={configuration} 
      onSubmit={(config) => {
        addToast("Updated PiM config", "success")
       }}
      
      onChange={(key, value) => {
        const copy_configuration = {...configuration}
        if(key === "pimsEnabled"){
          copy_configuration.pimsEnabled = value;
        }
        if(key === "integrationType"){
          copy_configuration.integrationType = value;
        }
        if(key === "publicKey"){
          copy_configuration.publicKey = value;
        }
        if(key === "privateKey"){
          copy_configuration.privateKey = value;
        }
        if(key === "installationKey"){
          copy_configuration.installationKey = value;
        }
        setConfig(copy_configuration)
       }} />
    </div>
  );
};

export default EditCustomerPage;
